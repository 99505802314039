.heading {
  text-align: center;
  text-shadow: 0 0 15px;
  font-size: 60px;
  font-weight: 60px;
  padding-top: 100px;
}
.subheading {
  padding-top: 30px;
  font-size: 35px;
  text-align: center;

  color: black;

  font-weight: bolder;
}
.Heading-main {
  justify-content: center;
  text-align: center;

  background-color: antiquewhite;
  padding-bottom: 180px;
}
.subheading-content {
  text-align: center;
  line-height: normal;
  font-size: 14px;
}
.sub-subheading {
  font-size: 20px;
  color: rgb(100, 97, 97);
  font-style: bold;
  font-weight: bold;
  margin-top: 25px;
}
.img {
  width: 90%;

  margin: 10px 10px;
}
.services-structure {
  background-color: rgb(230, 221, 211);
}
.services-table {
  justify-content: left;
  text-align: left;
  text-align: left;
}
.logo {
  width: 120px;
  height: 50px;
}

.lead-gen img {
  width: 60px;
  height: 60px;
  position: fixed;
  left: 5px;
  bottom: 50%;
}

.lead-gen {
  width: 60px;
  height: 60px;
  position: fixed;
  left: 50%;
  bottom: 50%;
}
.wp-img img {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 30px;
  bottom: 30px;
}
.count {
  border: 2px solid rgb(235, 226, 226);
  padding: 10px 5px;
  border-radius: 10px;
  background-color: chartreuse;
  color: blue;
}

.success-count {
  font-size: 100px;
}
.dm-img-pos {
  justify-content: center;
  text-align: center;
  align-items: center;

  margin: auto;
}
